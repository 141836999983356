import React, { ReactNode, useCallback, useRef } from 'react';
import { useEventListener } from 'finbox-ui-kit/utils/hooks/use-event-listener';
import { uid } from '../utils/uid';

type TFUIModalsContext = {
    register: (modalId: string, closeHandler: () => void, hideScroll: boolean) => number
    unregister: (modalId: string) => void
    getModalId: () => string
};


const FUIModalsContext = React.createContext<TFUIModalsContext>({
    getModalId: () => null,
    register: () => null,
    unregister: () => null,
});

export function useFUIModalsContext() {
    return React.useContext(FUIModalsContext);
}

type TFUIModalsRef = TFUIModalsContext & {
    modals: Set<string>
    modalsProps: Record<string, () => void>
};

type TFUIModalsProps = {
    children?: ReactNode
};
export const FUIModalsProvider = ({ children }: TFUIModalsProps) => {
    const ref = useRef<TFUIModalsRef>({
        modals: new Set(),
        modalsProps: {},
    } as TFUIModalsRef);

    const handlerKeydown = useCallback((e) => {
        if (e.key === 'Escape') {
            if (ref.current.modals.size) {
                const upperWindowID = Array.from(ref.current.modals).pop();
                ref.current.modalsProps[upperWindowID]();
            }
        }
    }, []);

    useEventListener('keydown', handlerKeydown);

    function register(modalId: string, modalProps: () => void, hideScroll: boolean) {
        if (hideScroll && !ref.current.modals.size) {
            document.body.style.overflow = 'hidden';
        }
        ref.current.modals.add(modalId);
        ref.current.modalsProps[modalId] = modalProps;
        return ref.current.modals.size;
    }

    function unregister(modalId: string) {
        ref.current.modals.delete(modalId);
        delete ref.current.modalsProps[modalId];
        if (!ref.current.modals.size) {
            document.body.style.overflow = null;
        }
    }

    function getModalId() {
        return uid();
    }

    return (
        <FUIModalsContext.Provider value={ {
            getModalId,
            register,
            unregister,
        } }>
            { children }
        </FUIModalsContext.Provider>
    );
};
