/* eslint-disable no-underscore-dangle */
import has from 'lodash/has';
import cloneDeep from 'lodash/cloneDeep';
import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { Jwt } from '@/libs/jwt';
import { getFingerprint } from '@/utils/fingerprint';


export type TResponseError = {
    code: number,
    message: string;
    scope: string;
}

export type TResponseErrorV2 = {
    statusCode: number;
    errorCode: number;
    errorMessage: string;
    timestamp: string;
    path: string;
}

type TRequestResult = {
    response: any;
    error?: TResponseError;
    headers?: any[];
}

type TRequestOptions = {
    useToken?: boolean;
    requestConfig?: AxiosRequestConfig;
}

export type TRequestAttachment = [ string, File ];

const request = {
    _normalizePageParams: (params) => {
        params = cloneDeep(params);
        if (has(params, 'sorters')) {
            params.sorters = JSON.stringify(params.sorters);
        }
        return params;
    },
    /**
     * Parsing success response
     * @param response
     * @returns {{response: *}}
     * @private
     */
    _parseSuccessResponse: (response) => {
        if ([ 200, 201 ].includes(response.status)) {
            return {
                response: response.data,
                headers: response.headers,
            };
        }
        throw new Error('Response parse error');
    },

    /**
     * Parsing error response
     */
    _parseErrorResponse: (requestError: AxiosError<any>) => {
        if (requestError && requestError.response) {
            if (requestError.response.config.responseType === 'arraybuffer') {
                const utf8decoder = new TextDecoder();
                try {
                    return JSON.parse(utf8decoder.decode(requestError.response.data));
                } catch (err) {
                    return {
                        error: {
                            code: 50000,
                            message: requestError.message || 'Unrecognized error',
                            scope: 'system',
                        },
                    };
                }
            }
            if (typeof requestError.response.data === 'object') {
                /*
                Обработка ошибок API v2
                 */
                if ('errorCode' in requestError.response.data) {
                    return {
                        error: {
                            code: requestError.response.data.errorCode,
                            message: requestError.response.data.errorMessage,
                            scope: 'client',
                        },
                    };
                }
                return requestError.response.data;
            }
            switch (requestError.response.status) {
                case 401:
                    return {
                        error: {
                            code: 40100,
                            message: 'Unauthorized',
                            scope: 'system',
                        },
                    };
                case 403:
                    return {
                        error: {
                            code: 40300,
                            message: 'Forbidden',
                            scope: 'system',
                        },
                    };
                case 404:
                    return {
                        error: {
                            code: 40400,
                            message: 'Route not found',
                            scope: 'system',
                        },
                    };
                case 500:
                    return {
                        error: {
                            code: 50000,
                            message: 'Internal server error',
                            scope: 'system',
                        },
                    };
                case 502:
                    return {
                        error: {
                            code: 50200,
                            message: 'Server temporary unavailable',
                            scope: 'system',
                        },
                    };
                default:
                    return {
                        error: {
                            code: 50000,
                            message: 'Unknown response error',
                            scope: 'system',
                        },
                    };
            }
        }
        return {
            error: {
                code: 50000,
                message: requestError.message || 'Unrecognized error',
                scope: 'system',
            },
        };
    },

    async _makeRequest(requestPromise): Promise<TRequestResult> {
        try {
            const response = await requestPromise;
            return this._parseSuccessResponse(response);
        } catch (error) {
            return await this._parseErrorResponse(error);
        }
    },

    async _getHeaders(useToken: boolean, additionalHeaders: any = {}) {
        const headers: any = {
            'X-sec': await getFingerprint(),
            ...additionalHeaders,
        };
        if (useToken) {
            const accessToken = await Jwt.instance().getAccessToken();
            headers.Authorization = `Bearer ${ accessToken }`;
        }
        return headers;
    },

    async post(url: string, data?, useToken = true): Promise<TRequestResult> {
        return this._makeRequest(
            axios.post(url, data, { headers: await this._getHeaders(useToken) }),
        );
    },

    async put(url: string, data?, useToken = true): Promise<TRequestResult> {
        return this._makeRequest(
            axios.put(url, data, { headers: await this._getHeaders(useToken) }),
        );
    },

    async patch(url: string, data?, useToken = true): Promise<TRequestResult> {
        return this._makeRequest(
            axios.patch(url, data, { headers: await this._getHeaders(useToken) }),
        );
    },

    async get(url: string, params?, useToken = true, additionalConfig = {}): Promise<TRequestResult> {
        return this._makeRequest(
            axios.get(url, {
                headers: await this._getHeaders(useToken),
                params: this._normalizePageParams(params),
                ...additionalConfig,
            }),
        );
    },

    async delete(url: string, params?, useToken = true): Promise<TRequestResult> {
        return this._makeRequest(
            axios.delete(url, { headers: await this._getHeaders(useToken), params }),
        );
    },

    async upload(url: string, file: File, options?: TRequestOptions): Promise<TRequestResult> {
        const useToken = options?.useToken !== undefined ? options.useToken : true;
        const config = options?.requestConfig || {};
        config.headers = await this._getHeaders(useToken);
        const data = new FormData();
        data.append('file', file);

        return this._makeRequest(axios.post(url, data, config));
    },

    async postWithAttachments(method: 'POST' | 'PUT', url: string, data: any, attachments: TRequestAttachment[], options?: TRequestOptions): Promise<TRequestResult> {
        const useToken = options?.useToken !== undefined ? options.useToken : true;
        const config = options?.requestConfig || {};

        config.headers = await this._getHeaders(useToken, {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data; charset=utf-8',
        });

        const formData = new FormData();
        formData.append("data", JSON.stringify(data));
        if (attachments && attachments.length > 0) {
            for (const attachment of attachments) {
                const uint8array = new TextEncoder().encode(attachment[1].name);
                const str = new TextDecoder('utf8').decode(uint8array);
                const blob = attachment[1].slice(0, attachment[1].size, attachment[1].type);
                const f = new File([ blob ], encodeURIComponent(str));
                formData.append(attachment[0], f);
            }
        }
        return this._makeRequest(axios[method.toLowerCase()](url, formData, config));
    },
};

export {
    request as default,
    request,
};
