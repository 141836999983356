import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { UIButton, UIDatesPresets, UIOptionsList } from 'finbox-ui-kit';
import { UIModalProps } from 'finbox-ui-kit/components/modal/modal';
import { DEFAULT_DATES_PRESETS_TIME, DEFAULT_DATES_PRESETS_HOURS, DEFAULT_DATES_PRESETS_DAYS } from 'finbox-ui-kit/components/dates-presets';
import { TUIOptionsSelectHandler } from 'finbox-ui-kit/components/options-list/options';
import { Scheme, SchemeScheme } from '@/utils';
import { API_URLS, E_TASK_TYPE, MTaskType } from '@/consts';
import { UITextarea } from '@/common/finbox-ui-kit/src/components/text-area';
import { UIOverlayModal } from '@/common/finbox-ui-kit/src/components/overlay-modal';
import './lead-task-form.style..scss';
import { useApiClient } from '@/libs/api-client/use-api-client';
import { DatePicker } from '@/common/ui/date-picker';


const SCHEME: SchemeScheme = {
    date: {
        type: Scheme.SCHEME_RULE_TYPES.DATE,
        required: true,
        minDate: DateTime.now().startOf('day'),
    },
    type: {
        type: Scheme.SCHEME_RULE_TYPES.STRING,
        required: true,
    },
    text: {
        type: Scheme.SCHEME_RULE_TYPES.STRING,
        required: true,
    },
};

type TFetchData = {
    lid: number;
    remind: DateTime;
    text: string;
    type: E_TASK_TYPE;
}

type TTaskFormProps = UIModalProps & {
    lid: number;
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (fields: TFetchData) => void;
};

type TErrors = {
    date: string;
    time: string;
    text: string;
    type: string;
}

const TASK_TEXT_SUGGESTIONS = [
    'Не отвечает',
    'Перезвонить',
    'Высылает документы',
    'Проверить ЕГРН',
    'Повторная заявка',
    'Не актуально',
    'Нет залога',
    'Думает',
    'В работе',
    'На рассмотрении',
    'На рассмотрении ЧИ',
    'На регистрации',
    'Отказ',
    'Не ликвидная недвижимость',
    'Не интересует кредит',
    'Отправить смс',
    'Присутствуют доли',
    'Должен перезвонить',
    'Другой регион',
    'Деревянный дом',
    'Должен прислать фото',
    'Уточнить актуальность',
];

const TaskForm = ({ lid, onSubmit, ...modalProps }: TTaskFormProps) => {
    const [ date, setDate ] = useState(DateTime.now());
    const [ type, setType ] = useState<E_TASK_TYPE>(null);
    const [ text, setText ] = useState('');
    const [ timePresetPicked, setTimePresetPicked ] = useState(false);
    const [ pickedTimePreset, setPickedTimePreset ] = useState(null);
    const [ pickedHoursPreset, setPickedHoursPreset ] = useState(null);
    const [ pickedDatePreset, setPickedDatePreset ] = useState(null);
    const [ suggestions, setSuggestions ] = useState<string[]>(TASK_TEXT_SUGGESTIONS);
    const [ errors, setErrors ] = useState<TErrors>({
        date: null,
        time: null,
        text: null,
        type: null,
    });

    const { loading: isLoading, fetch } = useApiClient({
        method: 'post',
        url: API_URLS.LEADS.TASKS,
        successMessage: 'Напоминание добавлено',
    });

    useEffect(() => {
        setDate(DateTime.now().plus({ minute: 15 }));
        setText('');
        setErrors({
            date: null,
            time: null,
            text: null,
            type: null,
        });
        setSuggestions(TASK_TEXT_SUGGESTIONS);
        setTimePresetPicked(false);
        setPickedTimePreset(null);
        setPickedHoursPreset(null);
        setPickedDatePreset(null);
    }, [ modalProps.open ]);

    const dateChangeHandler = (e, { value }) => {
        setDate(value);
        setErrors((c) => ({
            ...c,
            date: null,
        }));
        setPickedTimePreset(null);
        setPickedHoursPreset(null);
        setPickedDatePreset(null);
    };

    const textChangeHandler = (e, { value }) => {
        setText(value);
        setErrors((c) => ({
            ...c,
            text: null,
        }));
        setSuggestions(
            !value
                ? TASK_TEXT_SUGGESTIONS
                : TASK_TEXT_SUGGESTIONS.filter((i) => i.toLowerCase().includes(value.toLowerCase())),
        );
    };

    const typeChangeHandler = (value: E_TASK_TYPE) => () => {
        setType(value);
        setErrors((c) => ({
            ...c,
            type: null,
        }));

        if (!text && value === E_TASK_TYPE.CALLBACK) {
            setText('Перезвонить');
        } else if (text === 'Перезвонить') {
            setText('');
        }
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        const { isValid, errors: validationErrors } = Scheme.validate<TErrors>(SCHEME, {
            date,
            type,
            text,
        });

        if (!isValid) {
            setErrors(validationErrors);
            return;
        }

        const fetchData: TFetchData = {
            lid,
            // @ts-ignore
            remind: date,
            type,
            text,
        };

        const response = await fetch(fetchData);
        modalProps.onClose();
        onSubmit(response.data);
    };

    const handlerClickAddDate = (presetTime: number) => {
        const datetime = DateTime.now().plus({ day: presetTime });
        setDate((date) => (date || DateTime.now()).set({
            day: datetime.day,
            month: datetime.month,
            year: datetime.year,
            ...(!timePresetPicked && {
                hour: 9,
                minute: 0,
                second: 0,
            }),
        }));
        setPickedDatePreset(presetTime);
    };

    const handlerClickAddTime = (presetTime: number) => {
        const datetime = DateTime.now().plus({ minute: presetTime });
        setDate((date) => (date || DateTime.now()).set({
            hour: datetime.hour,
            minute: datetime.minute,
            second: datetime.second,
        }));
        setTimePresetPicked(true);
        setPickedTimePreset(presetTime);
        setPickedHoursPreset(null);
    };

    const handlerClickTime = (presetTime: number) => {
        setDate((date) => (date || DateTime.now()).set({
            hour: presetTime,
            minute: presetTime === 13 ? 30 : 0,
            second: 0,
        }));
        setTimePresetPicked(true);
        setPickedHoursPreset(presetTime);
        setPickedTimePreset(null);
    };

    const handlerClickPresetText: TUIOptionsSelectHandler = ({ value }) => {
        setText(value as string);
    };
    return (
        <UIOverlayModal { ...modalProps }>
            <UIOverlayModal.Header>Напоминание</UIOverlayModal.Header>
            <UIOverlayModal.Body>
                <div className='lead-task-form'>
                    <form onSubmit={ submitHandler }>
                        <DatePicker
                            className='mb1'
                            label='Дата и время'
                            name='date'
                            disabled={ isLoading }
                            value={ date }
                            placeholder='Выберите дату'
                            onChange={ dateChangeHandler }
                            minDate={ DateTime.now() }
                            error={ errors.date }
                            showTimePicker
                        />
                        <div className='lead-task-form-presets'>
                            <UIDatesPresets
                                presets={ DEFAULT_DATES_PRESETS_TIME }
                                onClick={ handlerClickAddTime }
                                picked={ pickedTimePreset }
                                disabled={ isLoading }
                                inline
                            />
                            <UIDatesPresets
                                presets={ DEFAULT_DATES_PRESETS_HOURS }
                                onClick={ handlerClickTime }
                                picked={ pickedHoursPreset }
                                disabled={ isLoading }
                                inline
                            />
                            <UIDatesPresets
                                presets={ DEFAULT_DATES_PRESETS_DAYS }
                                onClick={ handlerClickAddDate }
                                picked={ pickedDatePreset }
                                disabled={ isLoading }
                                inline
                            />
                        </div>
                        <div className='mb2 mt1'>
                            <b><small>Тип напоминания</small></b> <br/>
                            <UIButton
                                type='button'
                                content={ MTaskType.get(E_TASK_TYPE.CALLBACK) }
                                onClick={ typeChangeHandler(E_TASK_TYPE.CALLBACK) }
                                color={ type === E_TASK_TYPE.CALLBACK ? 'green' : null }
                                size='tiny'
                                className='mr-5'
                                disabled={ isLoading }
                            />
                            <UIButton
                                type='button'
                                content={ MTaskType.get(E_TASK_TYPE.REMIND) }
                                onClick={ typeChangeHandler(E_TASK_TYPE.REMIND) }
                                color={ type === E_TASK_TYPE.REMIND ? 'green' : null }
                                size='tiny'
                                disabled={ isLoading }
                            />
                            { errors.type && (
                                <p className='color-red mt-5'>
                                    { errors.type }
                                </p>
                            ) }
                        </div>

                        <UITextarea
                            label='Текст'
                            placeholder='Текст напоминания'
                            rows={ 4 }
                            name='text'
                            value={ text }
                            onChange={ textChangeHandler }
                            error={ errors.text }
                            disabled={ isLoading }
                            clearable
                        />

                        <div className='lead-task-form-suggestions'>
                            <UIOptionsList
                                options={ suggestions.map((i) => ({
                                    value: i,
                                    text: i,
                                })) }
                                onSelect={ handlerClickPresetText }
                                disabled={ isLoading }
                                fluid
                            />
                        </div>
                    </form>
                </div>
            </UIOverlayModal.Body>
            <UIOverlayModal.Footer>
                <UIButton
                    content='Добавить'
                    className='mr-5'
                    onClick={ submitHandler }
                    loading={ isLoading }
                    primary
                />
                <UIButton
                    content='Отмена'
                    onClick={ modalProps.onClose }
                    disabled={ isLoading }
                />
            </UIOverlayModal.Footer>
        </UIOverlayModal>
    );
};


export {
    TaskForm as default,
    TaskForm,
};
