import React from 'react';
import cn from 'classnames';
import { UIIcon } from 'finbox-ui-kit';
import { Validator } from 'finbox-ui-kit/libs/validator';
import { TCreditorContact } from '@/types/creaditors';
import { CreditorContactTypeIcon, CreditorContactTypeIconColor, ECreditorContactType } from '@/consts';
import { Phone } from '@/common';
import { Email } from '@/common/email';
import { categoriesMap, errorsIntl, fieldsIntl } from '@/components/leads/components/order-selection/_consts';
import { TDocumentValidationError, TParsedMessage } from '@/components/leads/contains/documents/types';

type CreditorContactProps = {
    contact: TCreditorContact;
    className?: string;
}
export function CreditorContact({ className, contact }: CreditorContactProps) {
    if (!contact) {
        return null;
    }
    switch (contact.type) {
        case ECreditorContactType.PHONE:
        case ECreditorContactType.WHATSAPP:
            return (
                <div className={ cn('nowrap', className) }>
                    <UIIcon
                        name={ CreditorContactTypeIcon.get(contact.type)[0] as any }
                        type={ CreditorContactTypeIcon.get(contact.type)[1] }
                        color={ CreditorContactTypeIconColor.get(contact.type) }
                        className='mr-5'
                    />
                    <Phone number={ contact.value }/>
                </div>
            );
        case ECreditorContactType.EMAIL:
            return (
                <div className={ cn('nowrap', className) }>
                    <UIIcon
                        name='envelope'
                        color='violet'
                        className='mr-5'
                    />
                    <Email email={ contact.value } name={ contact.name }/>
                </div>
            );
        case ECreditorContactType.SITE:
            return (
                <div className={ cn('nowrap', className) }>
                    <UIIcon
                        name='globe'
                        color='grey'
                        className='mr-5'
                    />
                    <a
                        href={ `https://${ contact.value }` }
                        target='_blank'
                        style={ {
                            display: 'inline-block',
                            maxWidth: 200,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            position: 'relative',
                            top: 4,
                        } }
                    >
                        { contact.value }
                    </a>
                </div>
            );
        case ECreditorContactType.TELEGRAM:
            return (
                <div className={ cn('nowrap', className) }>
                    <UIIcon
                        name={ CreditorContactTypeIcon.get(contact.type)[0] as any }
                        type={ CreditorContactTypeIcon.get(contact.type)[1] }
                        color={ CreditorContactTypeIconColor.get(contact.type) }
                        className='mr-5'
                    />
                    { Validator.phone(contact.value) === true
                        ? (
                            <Phone number={ contact.value }/>
                        )
                        : (
                            <a
                                href={`tg://resolve?domain=${contact.value.replace('@', '')}&text=Здравствуйте! Заявка на кредит.`}
                                target='_blank'
                            >
                                { contact.value }
                            </a>
                        ) }
                </div>
            );
        default:
            return contact.value as any;
    }
}


const parseErrorField = (field: string) => {
    const parts = field.split('.');
    if (parts.length === 1) {
        return {
            field,
            pattern: field,
            values: [],
        };
    }
    const digits = [];
    const partsPatterns = parts.map((part) => {
        const num = Number(part);
        if (!isNaN(num)) {
            digits.push(num);
            return '{d}';
        }
        return part;
    });
    return {
        field,
        pattern: partsPatterns.join('.'),
        values: digits,
    };
};

const replaceMessageValues = (message: string, values: number[]) =>
    message
        ? message.replace(/\{[0-9]+}/g, (match) => String(values[Number(match.slice(1, -1)) - 1]))
        : null;

export type TProgramSelectionError = {
    field: string;
    message: string;
    type: string;
    name: string;
}
export const _errorsParser = (errors: TProgramSelectionError[]): Record<string, TParsedMessage[]> => {
    const _errors: any = {};
    errors.forEach((error) => {
        let _category;
        Object.entries(categoriesMap).forEach(([ regex, category ]) => {
            const re = new RegExp(regex);
            if (re.test(error.field)) {
                _category = category;
            }
        });

        const parsedField = parseErrorField(error.field);

        _errors[_category || 'other'] = [
            ...(_errors[_category || 'other'] || []),
            {
                ...error,
                message: replaceMessageValues(
                    errorsIntl[`${ parsedField.pattern }[${ error.type }]`],
                    [],
                ) || error.message,
                name: replaceMessageValues(fieldsIntl[parsedField.pattern], parsedField.values),
            },
        ];
    });
    return _errors;
};


export const parseJoiErrors = (errors: TDocumentValidationError[]): Record<string, TParsedMessage[]> => errors.reduce((acc, error) => {
    const parsedField = parseErrorField(error.context.label);
    if (error.path[0] in acc) {
        return {
            ...acc,
            [error.path[0]]: [
                ...acc[error.path[0]],
                {
                    field: error.context.label,
                    message: error.message.replace(/"(.*)"/, ''),
                    name: replaceMessageValues(fieldsIntl[parsedField.field], parsedField.values),
                    type: error.type,
                },
            ],
        };
    }
    return {
        ...acc,
        [error.path[0]]: [
            {
                field: error.context.label,
                message: error.message.replace(/"(.*)"/, ''),
                name: replaceMessageValues(fieldsIntl[parsedField.field], parsedField.values),
                type: error.type,
            },
        ],
    };
}, {}) as Record<string, TParsedMessage[]>;