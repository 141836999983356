import React, { ReactNode } from 'react';

type TFinboxUIContext = {
    baseUrl?: string;
    iconsPath?: string;
};


const FinboxUIContext = React.createContext<TFinboxUIContext>({
    baseUrl: '/',
    iconsPath: null,
});

export function useFinboxUIContext() {
    return React.useContext(FinboxUIContext);
}

type TAppProviderProps = TFinboxUIContext & {
    children?: ReactNode
};
export const FinboxUIProvider: React.FC<TAppProviderProps> = ({ baseUrl = '/', children }) => (
    <FinboxUIContext.Provider value={{ baseUrl }}>
        { children }
    </FinboxUIContext.Provider>
)
