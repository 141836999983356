import React from 'react';
import cn from 'classnames';
import { TTableBody, TTableCell, TTableComponent, TTableFooter, TTableHeader, TTableHeaderCell, TTableRow } from './table';
import styles from './table.module.scss';

export const Table: TTableComponent = ({
    children,
    className,
    hover,
    striped,
    noBorder,
    ...elementProps
}) => (
    <table
        { ...elementProps }
        className={ cn(styles.table, className, {
            [styles.tableHover]: hover,
            [styles.tableStriped]: striped,
            '-no-border': noBorder,
        }) }
    >
        { children }
    </table>
);

export const TableHeader: TTableHeader = ({ children, className, ...elementProps }) => (
    <thead { ...elementProps } className={ cn(styles.tableRow, className) }>{ children }</thead>
);

export const TableBody: TTableBody = ({ children, className, ...elementProps }) => (
    <tbody { ...elementProps } className={ cn(styles.tableRow, className) }>{ children }</tbody>
);

export const TableFooter: TTableFooter = ({ children, className, ...elementProps }) => (
    <tfoot { ...elementProps } className={ cn(styles.tableRow, className) }>{ children }</tfoot>
);

export const TableRow: TTableRow = ({ children, className, ...elementProps }) => (
    <tr { ...elementProps } className={ cn(styles.tableRow, className) }>{ children }</tr>
);

export const TableCell: TTableCell = ({ children, className, textAlign, ...elementProps }) => (
    <td { ...elementProps } className={ cn(styles.tableCell, className, {
        [`text-${ textAlign }`]: textAlign,
    }) }>{ children }</td>
);

export const TableHeaderCell: TTableHeaderCell = ({ children, className, textAlign, ...elementProps }) => (
    <th { ...elementProps } className={ cn(styles.tableHeaderCell, className, {
        [`text-${ textAlign }`]: textAlign,
    }) }>{ children }</th>
);

Table.Header = TableHeader;
Table.Body = TableBody;
Table.Footer = TableFooter;
Table.Row = TableRow;
Table.Cell = TableCell;
Table.HeaderCell = TableHeaderCell;