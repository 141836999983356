import React from 'react';
import { UIModal } from 'finbox-ui-kit';
import { useModal } from 'finbox-ui-kit/utils/hooks';

export const PreviewImage = ({ children, src }) => {
    const { show, props } = useModal();

    return (
        <>
            <div onClick={ () => show() }>
                { children }
            </div>
            <UIModal
                { ...props }
                size='large'
                showCloseButton
            >
                <img src={ src } alt=''/>
            </UIModal>
        </>
    );
};
