import React, { useCallback, useRef, useState } from 'react';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import { UIIcon, UIPopup, UITooltip } from 'finbox-ui-kit';
import { useToggle } from 'finbox-ui-kit/utils/hooks';
import { UIOverlayModal } from '@/common/finbox-ui-kit/src/components/overlay-modal';
import { SendEmail } from '@/common/send-email';
import { OptionsList, TOption, TSelectHandler } from '@/common/options-list';


type TProps = {
    email: string;
    name?: string;
    text?: string;
    subject?: string;
}

export const Email: React.FC<TProps> = ({ email, name = '', text, subject }) => {
    const [ send, setSend ] = useState(false);
    const sendClickHandler = useCallback(() => {
        setSend(true);
    }, []);
    const copyClickHandler = useCallback(() => {
        copy(email);
        toast.info('Email скопирован');
    }, [ email ]);
    const closeSendHandler = () => {
        setSend(!send);
    };

    const ref = useRef<HTMLDivElement>();
    const { on, toggle } = useToggle();
    const options: TOption[] = [
        {
            text: 'Отправить Email',
            value: 'send',
            icon: 'paper-plane',
            iconColor: 'teal',
        },
        {
            text: 'Копировать',
            value: 'copy',
            icon: 'copy',
            iconColor: 'grey',
        },
    ];
    const handlers = {
        'send': sendClickHandler,
        'copy': copyClickHandler,
    };
    const handlerSelectChannel: TSelectHandler = ({ value }) => {
        toggle(false);
        if (value as string in handlers) {
            handlers[value as any]();
        }
    };
    return (
        <UIPopup.Wrapper>
            <div ref={ ref } className='nowrap cursor-pointer' onClick={ () => toggle() } style={{ display: 'flex', alignItems: 'center' }}>
                <UITooltip
                    trigger={(ref) => (
                        <span ref={ref}>{ email }</span>
                    )}
                    content={ name || email}
                />
                <UIIcon name='message-lines' className='ml-5' color='grey' type='light' size='small'/>
            </div>
            <UIPopup targetRef={ ref } open={ on } onClickOutside={ () => toggle(false) } minWidth={ 0 }>
                <OptionsList options={ options } onSelect={ handlerSelectChannel }/>
            </UIPopup>

            <UIOverlayModal open={ send } onClose={ closeSendHandler }>
                <UIOverlayModal.Header>Новое письмо</UIOverlayModal.Header>
                <UIOverlayModal.Body>
                    { send && (
                        <SendEmail
                            to={ [ {
                                name: name || email,
                                address: email,
                            } ] }
                            onSent={ closeSendHandler }
                            body={ text }
                            subject={ subject }
                        />
                    ) }
                </UIOverlayModal.Body>
            </UIOverlayModal>
        </UIPopup.Wrapper>
    );
};
