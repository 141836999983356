import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app';
import './utils/tab-unique-identificator';


const container = document.getElementById('root');
const root = createRoot(container);

// // @ts-ignore
// if (module.hot) {
//     // @ts-ignore
//     module.hot.accept('./app.tsx', () => {
//         root.render(<App />);
//     })
// }
root.render(<App />);
