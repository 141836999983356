import axios, { AxiosInstance } from 'axios';
import { getFingerprint } from '@/utils/fingerprint';
import { HttpClient } from '@/libs/api-client/http-client';

export class PublicApiClient extends HttpClient {
    protected static _instance?: PublicApiClient;

    constructor(_client: AxiosInstance) {
        super(_client);
    }

    private static instance(): PublicApiClient {
        if (!this._instance) {
            throw new Error('PublicApiClient is not initialized');
        }
        return PublicApiClient._instance;
    }

    public static async init(baseURL: string) {
        if (PublicApiClient._instance) {
            throw new Error('PublicApiClient is already initialized');
        }
        PublicApiClient._instance = new PublicApiClient(
            axios.create({
                baseURL,
                headers: {
                    'Content-Type': 'application/json',
                    'X-sec': await getFingerprint(),
                },
            }),
        );
    }

    public static get(...args: Parameters<HttpClient['get']>) {
        return PublicApiClient.instance().get(...args);
    }

    public static post(...args: Parameters<HttpClient['post']>) {
        return PublicApiClient.instance().post(...args);
    }

    public static put(...args: Parameters<HttpClient['put']>) {
        return PublicApiClient.instance().put(...args);
    }

    public static patch(...args: Parameters<HttpClient['patch']>) {
        return PublicApiClient.instance().patch(...args);
    }

    public static delete(...args: Parameters<HttpClient['delete']>) {
        return PublicApiClient.instance().delete(...args);
    }

    public static request(...args: Parameters<HttpClient['request']>) {
        return PublicApiClient.instance().request(...args);
    }
}