import React, { useCallback, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { toast } from 'react-toastify';
import { UIButton, UIFilePicker, UILoadingOverlay } from 'finbox-ui-kit';
import { request, Scheme, SchemeScheme } from "@/utils";
import { API_URLS } from "@/consts";
import { TargetPicker } from "@/common/send-email/target-picker";

import { blobToBase64 } from '@/utils/base64';

import './styles.scss';
import { AttachOrder } from '@/common/send-email/components/attach-order';
import { BundledEditor } from '@/common/send-email/components/editor';
import { Input } from '@/common/ui/input';


const SCHEME: SchemeScheme = {
    body: {
        type: Scheme.SCHEME_RULE_TYPES.STRING,
    },
    subject: {
        type: Scheme.SCHEME_RULE_TYPES.STRING,
        required: true,
    },
    to: {
        type: Scheme.SCHEME_RULE_TYPES.ARRAY,
        required: true,
        minLength: 1,
    },
    cc: {
        type: Scheme.SCHEME_RULE_TYPES.ARRAY,
    },
    bcc: {
        type: Scheme.SCHEME_RULE_TYPES.ARRAY,
    },
};

export type TEmailContact = {
    name: string;
    address: string;
}

type TSendEmailProps = {
    to?: TEmailContact[];
    onSent?: () => void;
    cc?: TEmailContact[];
    bcc?: TEmailContact[];
    subject?: string;
    body?: string;
    additionalData?: Record<string, any>;
}

export const SendEmail: React.FC<TSendEmailProps> = ({
    to = [],
    onSent,
    cc,
    bcc,
    subject,
    body,
    additionalData,
}) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ fields, setFields ] = useState({
        to: to,
        body: body || '',
        subject: subject || '',
        cc: cc,
        bcc: bcc,
        attachments: [],
    });

    const [ errors, setErrors ] = useState({
        to: null,
        body: null,
        subject: null,
        cc: null,
        bcc: null,
    });

    const submitHandler = useCallback(async () => {
        const { isValid, errors } = Scheme.validate<any>(SCHEME, fields);

        if (!isValid) {
            setErrors(errors);
            return;
        }

        const data = cloneDeep(fields);

        const { cc, bcc, attachments } = fields;
        if (!cc || !cc.length) {
            delete data.cc;
        }
        if (!bcc || !bcc.length) {
            delete data.bcc;
        }
        const files = data.attachments.map((i) => i.file);
        delete data.attachments;
        if (!attachments.length) {
            delete data.attachments;
        }
        setIsLoading(true);
        try {
            const { error } = await request.postWithAttachments(
                'POST',
                API_URLS.EMAIL.SEND,
                data,
                files.map((file) => [ 'file', file ]),
            );
            if (error) {
                toast.error(error.message);
                return;
            }
        } finally {
            setIsLoading(false);
        }
        toast.success('Письмо отправлено');
        if (onSent) {
            onSent();
        }
    }, [ fields, onSent ]);

    const fieldChangeHandler = useCallback((e, { name, value }) => {
        setFields((c) => ({ ...c, [name]: value }));
        setErrors((c) => ({ ...c, [name]: null }));
    }, []);

    const changeHandler = useCallback((content) => {
        setFields((c) => ({ ...c, body: content }));
        setErrors((c) => ({ ...c, body: null }));
    }, []);

    const addAttachments = useCallback(async (files: File[]) => {
        const attachments = await Promise.all(
            files.map(async (file) => ({
                filename: file.name,
                content: await blobToBase64(file),
                contentType: file.type,
                file,
            })),
        );
        setFields((c) => ({ ...c, attachments: attachments }));
    }, []);

    const handlerAttachOrder = React.useCallback(async (files: File[]) => {
        await addAttachments(files);
    }, [ addAttachments ]);

    return (
        <div className='relative'>
            <UILoadingOverlay active={ isLoading }/>
            <form>
                <TargetPicker
                    label='Кому'
                    value={ fields.to }
                    error={ errors.to }
                    name='to'
                    onChange={ fieldChangeHandler }
                />
                { !fields.cc && (
                    <div className='inlineBlock mr-5 mb-5 mt-5'>
                        <UIButton
                            icon='plus'
                            content='Копия'
                            onClick={ () => fieldChangeHandler(null, { name: 'cc', value: [] }) }
                            size='small'
                            color='white'
                        />
                    </div>
                ) }
                { fields.cc && (
                    <div className='mt1'>
                        <TargetPicker
                            label='Копия'
                            value={ fields.cc }
                            error={ errors.cc }
                            name='cc'
                            onChange={ fieldChangeHandler }
                        />
                    </div>
                ) }
                { !fields.bcc && (
                    <div className='inlineBlock mb1 mt-5'>
                        <UIButton
                            icon='plus'
                            content='Скрытая копия'
                            onClick={ () => fieldChangeHandler(null, { name: 'bcc', value: [] }) }
                            size='small'
                            color='white'
                        />
                    </div>
                ) }
                { fields.bcc && (
                    <div className='mb1 mt1'>
                        <TargetPicker
                            label='Скрытая копия'
                            value={ fields.bcc }
                            error={ errors.bcc }
                            name='bcc'
                            onChange={ fieldChangeHandler }
                        />
                    </div>
                ) }
                <Input
                    className='mb1'
                    label='Тема'
                    name='subject'
                    value={ fields.subject }
                    error={ errors.subject }
                    onChange={ fieldChangeHandler }
                />

                <div className='mb1'>
                    <BundledEditor
                        id='body'
                        value={ fields.body }
                        onEditorChange={ changeHandler }
                    />
                    { errors.body && (
                        <p className='-error color-red fz12'>{ errors.body }</p>
                    ) }
                </div>

                <UIFilePicker
                    columns={ 2 }
                    onChange={ async (files) => {
                        await addAttachments(files);
                    } }
                    fluid
                />

                { additionalData?.clientId && (
                    <AttachOrder
                        onAttach={ handlerAttachOrder }
                        clientId={ additionalData.clientId }
                        user={ additionalData.user }
                    />
                ) }
            </form>
            <UIButton
                className='mt1-5'
                icon='paper-plane'
                content='Отправить'
                onClick={ submitHandler }
                loading={ isLoading }
                primary
            />
        </div>
    );
};
