/* eslint-disable no-console */
import React, { ReactNode, useCallback, useEffect, useRef } from 'react';
import { io as ioClient, Socket } from 'socket.io-client';
import { useJwtContext } from '@/libs/jwt/jwt.context';


type TSocketsContext = {
    connect(): void;
    disconnect(): void;
    subscribe(event: string, callback: (data: any) => void): void;
    unsubscribe(event: string, callback: (data: any) => void): void;
};


const SocketsContext = React.createContext<TSocketsContext>(null);

export function useSocketsContext() {
    return React.useContext(SocketsContext);
}

type TSocketsProps = {
    children?: ReactNode
};
export const SocketsProvider = ({ children }: TSocketsProps) => {
    const _instance = useRef<Socket>(null);
    const { getAccessToken } = useJwtContext();
    useEffect(() => {
        console.log('[Sockets] INIT SOCKETS');
        _instance.current = ioClient('/sockets', {
            path: '/sockets',
            transports: [ 'websocket' ],
            autoConnect: false,
            auth: (cb) => {
                getAccessToken().then((token) => cb({ token }));
            },
        });
        _instance.current.on('connect', () => {
            console.log('[Sockets] connected');
        });
        return () => {
            _instance.current?.disconnect();
        };
    }, [ getAccessToken ]);


    const connect = useCallback(() => {
        console.log('[Sockets] connect');
        _instance.current.connect();
    }, []);

    const disconnect = useCallback(() => {
        _instance.current?.disconnect();
    }, []);

    const subscribe = useCallback((event: string, callback: (data: any) => void) => {
        // console.log('[Sockets] subscribe', event);
        _instance.current.on(event, callback);
    }, []);

    const unsubscribe = useCallback((event: string, callback: (data: any) => void) => {
        // console.log('[Sockets] unsubscribe', event);
        _instance.current.off(event, callback);
    }, []);

    return (
        <SocketsContext.Provider value={ {
            connect,
            disconnect,
            subscribe,
            unsubscribe,
        } }>
            { children }
        </SocketsContext.Provider>
    );
};
