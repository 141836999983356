import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { request } from '@/utils/request';

export const useDownloadFile = () => {
    const [ isLoading, setIsLoading ] = useState(false);
    const download = useCallback(async (url, open = false, returnFile = false, returnBlob = false): Promise<File | Blob | null> => {
        setIsLoading(true);
        try {
            const {
                response,
                headers,
                error,
            } = await request.get(
                url,
                null,
                true,
                {
                    responseType: 'arraybuffer',
                },
            );
            if (error) {
                toast.error('Произошла ошибка при формировании файла');
                return null;
            }

            const blob = new Blob([ response ], { type: headers['content-type'] });

            if (returnBlob) {
                return blob;
            }

            if (returnFile) {
                return new File([ blob ], decodeURI(headers['file-name']), { type: headers['content-type'] });
            }

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.target = '_blank';
            if (!open) {
                link.download = decodeURI(headers['file-name']);
            }
            document.body.appendChild(link);
            link.click();
            // link.parentElement.removeChild(link);
        } finally {
            setIsLoading(false);
        }
        return null;
    }, []);
    return { isLoading, download };
};
