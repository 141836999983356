import { Link } from 'react-router-dom';
import React from 'react';
import { TUIBreadcrumbsProps } from './breadcrumbs';

import { useFinboxUIContext } from '../../context/finbox-ui.context';
import { UIIcon } from '../icon';
import styles from './breadcrumbs.module.scss';

export const UIBreadcrumbs: React.FC<TUIBreadcrumbsProps> = ({ items, indexLink }) => {
    const { baseUrl } = useFinboxUIContext();
    return (
        <ul className={styles.FUIBreadcrumbs}>
            <li>
                <Link to={indexLink || baseUrl}>
                    <UIIcon name='house' type='sharp-solid'/>
                </Link>
            </li>
            { items.map((item, index) => (
                <li key={ index }>
                    <Link to={ item.link }>
                        { item.name }
                    </Link>
                </li>
            )) }
        </ul>
    )
}
