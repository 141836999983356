import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { DaDataAddressBounds } from 'react-dadata';
import { useDebounce } from 'finbox-ui-kit/utils/hooks';
import { InputSuggestions, InputSuggestionsOptions } from '@/common/ui/input-suggestions';
import { TInputProps } from '@/common/ui/input/types';
import { useApiClient } from '@/libs/api-client/use-api-client';
import { API_URLS } from '@/consts';
import { Nullable } from '@/types/_helpers';
import { TAddress } from '@/types/address';
import styles from './input-address.module.scss';

type InputAddressProps = Omit<TInputProps, 'value' | 'onChange'> & {
    value: Nullable<TAddress>;
    onChange(value: Nullable<TAddress>, options: InputSuggestionsOptions<TAddress>): void;
    fromBound?: DaDataAddressBounds,
    toBound?: DaDataAddressBounds,
};

export const InputAddress = React.forwardRef<HTMLInputElement, InputAddressProps>(function InputAddress(
    {
        value,
        onChange,
        className,
        fromBound,
        toBound,
        ...inputProps
    },
): React.ReactElement {
    const ref = useRef({
        cleared: true,
    });
    const [ inputValue, setInputValue ] = useState('');
    const [ suggestions, setSuggestions ] = useState([]);
    const { debounce } = useDebounce({ timeout: 300 });
    const { loading, fetch } = useApiClient({
        url: API_URLS.DICTIONARIES.DADATA.ADDRESS,
    });

    useEffect(() => {
        if (value) {
            setInputValue(value.value);
        }
    }, [ value ])

    function handlerChange(value: Nullable<any>, { option }: InputSuggestionsOptions<TAddress>) {
        setInputValue(value);
        ref.current.cleared = false;

        if (option) {
            onChange(option.data, { name: inputProps.name, value: option.data });
            return;
        }

        if (value) {
            debounce(async () => {
                const { suggestions } = await fetch({
                    query: value,
                    fromBound: fromBound,
                    toBound: toBound,
                });
                setSuggestions(suggestions.map((i) => ({
                    text: i.value,
                    value: i.value,
                    data: i,
                })));
            });
        } else {
            onChange(null, { name: inputProps.name, value: null });
            setSuggestions([]);
        }
    }

    async function handlerBlur() {
        if (inputValue && !ref.current.cleared) {
            const data = await fetch({
                query: inputValue,
            }, {
                url: API_URLS.DICTIONARIES.DADATA.ADDRESS_CLEAN,
            });
            const dadataResult = data?.data?.[0];
            if (dadataResult && dadataResult.qc === 0) {
                const val = {
                    data: dadataResult,
                    value: dadataResult.result,
                    unrestricted_value: dadataResult.result,
                };
                onChange(val, { name: inputProps.name, value: val });
                ref.current.cleared = true;
            }
        }
    }

    return (
        <div className={ cn(styles.inputAddress, className) }>
            <InputSuggestions
                { ...inputProps }
                suggestions={ suggestions }
                loading={ loading }
                filtering={ false }
                autoComplete='off'
                onChange={ handlerChange }
                onBlur={ handlerBlur }
                value={ inputValue }
                selectOnNavigation
                rtl
            />
        </div>
    );
});