import axios, { AxiosInstance } from 'axios';
import { getFingerprint } from '@/utils/fingerprint';
import { HttpClient } from '@/libs/api-client/http-client';

export class ApiClient extends HttpClient {
    protected static _instance?: ApiClient;

    constructor(_client: AxiosInstance) {
        super(_client);
    }

    private static instance(): ApiClient {
        if (!this._instance) {
            throw new Error('ApiClient is not initialized');
        }
        return ApiClient._instance;
    }

    public static async init(baseURL: string, getToken: () => Promise<string | null>) {
        if (ApiClient._instance) {
            throw new Error('ApiClient is already initialized');
        }
        const client = axios.create({
            baseURL,
            headers: {
                'Content-Type': 'application/json',
                'X-sec': await getFingerprint(),
            },
        });
        client.interceptors.request.use(async (config) => {
            config.headers.Authorization = `Bearer ${ await getToken() }`;
            return config;
        });
        ApiClient._instance = new ApiClient(
            client,
        );
    }

    public static destroy() {
        this._instance = null;
    }

    public static get(...args: Parameters<HttpClient['get']>) {
        return ApiClient.instance().get(...args);
    }

    public static post(...args: Parameters<HttpClient['post']>) {
        return ApiClient.instance().post(...args);
    }

    public static put(...args: Parameters<HttpClient['put']>) {
        return ApiClient.instance().put(...args);
    }

    public static patch(...args: Parameters<HttpClient['patch']>) {
        return ApiClient.instance().patch(...args);
    }

    public static delete(...args: Parameters<HttpClient['delete']>) {
        return ApiClient.instance().delete(...args);
    }

    public static request(...args: Parameters<HttpClient['request']>) {
        return ApiClient.instance().request(...args);
    }
}