import React, { useCallback } from 'react';
import { UIButton } from 'finbox-ui-kit';
import { useNotificationsContext } from '@/context/notifications.context';
import { TNotification, TNotificationId } from '@/common/user-notifications/notifications.types';
import { Notification } from './notification';


export type TNotificationsListProps = {
    onClose: () => void;
}
export const NotificationsList: React.FC<TNotificationsListProps> = ({
    onClose,
}) => {
    const {
        notifications,
        clearNotifications,
        removeNotification,
        count,
        isLoadingRemove,
        isLoadingClear,
        handleClickNotification,
    } = useNotificationsContext();

    const handlerClickClear = useCallback(async () => {
        await clearNotifications();
        onClose();
    }, [ clearNotifications, onClose ]);

    const handlerClickRemove = useCallback(async (id: TNotificationId) => {
        await removeNotification(id);
        if (count === 0) {
            onClose();
        }
    }, [ count, onClose, removeNotification ]);

    const handlerClickNotification = useCallback(async (notification: TNotification) => {
        await handleClickNotification(notification);
        onClose();
    }, [ handleClickNotification, onClose ]);

    return (
        <div className='notifications-list'>
            { count > 0 && (
                <>
                    <ul>
                        { notifications.map((notification) => (
                            <Notification
                                key={ notification.id }
                                notification={ notification }
                                onClick={ handlerClickNotification }
                                onClickRemove={ handlerClickRemove }
                                loadingRemove={ isLoadingRemove === notification.id }
                                disabledRemove={ isLoadingClear }
                            />
                        )) }
                    </ul>
                    <UIButton
                        className='notifications-clear'
                        content='Очистить'
                        onClick={ handlerClickClear }
                        loading={ isLoadingClear }
                        color='white'
                    />
                </>
            ) }
            { count === 0 && (
                <div className='color-grayDarken nowrap mt1 mb1 text-center'>
                    Нет новых уведомлений
                </div>
            ) }
        </div>
    );
}
