import { useCallback, useEffect, useState, experimental_useEffectEvent as useEffectEvent, ReactNode, useRef } from 'react';
import { toast } from 'react-toastify';
import { ApiClient } from '@/libs/api-client/api-client';
import { THttpClientAttachment, THttpClientMethod } from '@/libs/api-client/http-client';
import { PublicApiClient } from '@/libs/api-client/public-api-client';
import { HttpClientCancelException } from '@/libs/api-client/cancel-exception';

type useApiClientProps = {
    method?: THttpClientMethod;
    url?: string;
    successMessage?: ReactNode;
    initialFetch?: boolean;
    initialData?: any;
    showErrorToast?: boolean;
    useToken?: boolean;
    attachments?: THttpClientAttachment[];
}

type useApiClientReturn<TData = any> = {
    fetch: (data?: any, options?: Partial<useApiClientProps>) => Promise<TData>;
    loading: boolean;
    data: TData | null;
    abort: () => void;
    reset: () => void;
    isFetched: boolean;
}

export function useApiClient<TData = any>({
    url,
    method,
    successMessage,
    initialFetch,
    initialData = null,
    showErrorToast = true,
    useToken = true,
}: useApiClientProps = {}): useApiClientReturn<TData> {
    const ref = useRef({
        abortController: null,
    })
    const [ loading, setLoading ] = useState(false);
    const [ isFetched, setIsFetched ] = useState(false);
    const [ data, setData ] = useState<TData>(initialData);

    useEffect(() => () => {
        ref.current.abortController?.abort();
    }, []);

    const fetch = useCallback(async (data?: any, options?: Partial<useApiClientProps>) => {
        setLoading(true);
        const client = useToken ? ApiClient : PublicApiClient;
        try {
            const request = client.request({
                method: method || 'get',
                url,
                data,
                ...options && options,
            });
            ref.current.abortController = request.abortController;
            const response = await request.request;

            setData(response.data);
            if (successMessage) {
                toast.success(successMessage);
            }
            setIsFetched(true);
            return response.data;
        } catch (error) {
            if (showErrorToast && !(error instanceof HttpClientCancelException)) {
                toast.error(error.message);
            }
            throw error;
        } finally {
            setLoading(false);
        }
    }, [ method, showErrorToast, successMessage, url, useToken ]);

    const initialFetchEvent = useEffectEvent(() => {
        fetch(initialData);
    });

    useEffect(() => {
        if (initialFetch) {
            initialFetchEvent();
        }
    }, [ fetch, initialFetch ]);

    const abort = useCallback(() => {
        ref.current.abortController?.abort();
    }, []);

    const reset = useCallback(() => {
        setData(initialData);
    }, [ initialData ]);

    return {
        fetch,
        loading,
        data,
        abort,
        reset,
        isFetched,
    };
}