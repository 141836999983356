import React from 'react';
import cn from 'classnames';
import { TUISize } from 'finbox-ui-kit/types/common';
import styles from './placeholder.module.scss';


type UIPlaceholderProps = {
    className?: string
    size?: TUISize
};

export function UIPlaceholder({ className, size }: UIPlaceholderProps) {
    return (
        <div className={ cn(styles.FUIPlaceholder, className, { [`-${ size }`]: size }) } />
    );
}