import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosResponseHeaders, CanceledError } from 'axios';
import { omit } from 'lodash';
import { HttpClientException } from '@/libs/api-client/exception';
import { HttpClientCancelException } from '@/libs/api-client/cancel-exception';


export type THttpClientMethod = 'get' | 'post' | 'put' | 'delete' | 'patch' /*| 'upload' | 'postWithAttachments' | 'download'*/;

export type THttpClientAttachment = [ string, File ];

type THttpClientRequestParams = {
    url: string;
    data?: any;
}

type THttpClientRequestOptions = AxiosRequestConfig & {
    attachments?: THttpClientAttachment[];
};


export class HttpClient {
    constructor(
        private _client: AxiosInstance,
    ) {
    }

    private _parseSuccessResponse(response: AxiosResponse) {
        return {
            data: response.data,
            headers: response.headers,
        };
    }

    private _parseErrorResponse(error) {
        if (error instanceof CanceledError) {
            throw new HttpClientCancelException();
        }
        if ((error as AxiosError<any>).response?.data) {
            const data = (error as AxiosError<any>).response.data;
            if (!data || typeof data !== 'object') {
                throw new HttpClientException(
                    'Ошибка запроса',
                    -10000,
                );
            }
            if ('errorCode' in data) {
                throw new HttpClientException(
                    data.errorMessage + (data.data && typeof data.data === 'string' ? `: ${ data.data }` : ''),
                    data.errorCode,
                );
            }
            if ('error' in data) {
                throw new HttpClientException(
                    data.error.message,
                    data.error.code,
                );
            }
        }

        throw new HttpClientException((error as AxiosError<any>).message);
    }

    private _makeRequest(params: THttpClientRequestParams): {
        request: Promise<{ data: any; headers: AxiosResponseHeaders; }>
        abortController: AbortController,
    } {
        const controller = new AbortController();
        const request = this._client({
            ...params,
            signal: controller.signal,
        });

        return {
            request: request
                .then(this._parseSuccessResponse)
                .catch((err) => {
                    this._parseErrorResponse(err);
                    return null;
                }),
            abortController: controller,
        };
    }

    private _makeRequestWithAttachments(params: THttpClientRequestParams, attachments: THttpClientAttachment[]): {
        request: Promise<{ data: any; headers: AxiosResponseHeaders; }>
        abortController: AbortController,
    } {
        const controller = new AbortController();

        const formData = new FormData();

        if (params.data) {
            formData.append("data", JSON.stringify(params.data));
        }
        if (attachments && attachments.length > 0) {
            for (const attachment of attachments) {
                const uint8array = new TextEncoder().encode(attachment[1].name);
                const str = new TextDecoder('utf8').decode(uint8array);
                const blob = attachment[1].slice(0, attachment[1].size, attachment[1].type);
                const f = new File([ blob ], encodeURIComponent(str));
                formData.append(attachment[0], f);
            }
        }
        const request = this._client({
            ...omit(params, [ 'data' ]),
            data: formData,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data; charset=utf-8',
            },
            signal: controller.signal,
        });

        return {
            request: request
                .then(this._parseSuccessResponse)
                .catch((err) => {
                    this._parseErrorResponse(err);
                    return null;
                }),
            abortController: controller,
        };
    }

    private _makeRequestWithBody(method: THttpClientMethod, url: string, data?: any, params?: THttpClientRequestOptions) {
        if (params?.attachments) {
            return this._makeRequestWithAttachments({
                method,
                url,
                ...data && { data },
                ...params && omit(params, [ 'attachments' ]),
            }, params.attachments);
        }
        return this._makeRequest({
            method,
            url,
            ...data && { data },
            ...params && params,
        });
    }

    public get(url: string, data?: any, params?: THttpClientRequestOptions) {
        return this._makeRequest({
            method: 'get',
            url,
            ...data && { params: data },
            ...params && params,
        });
    }

    public delete(url: string, data?: any, params?: THttpClientRequestOptions) {
        return this._makeRequest({
            method: 'delete',
            url,
            ...data && { params: data },
            ...params && params,
        });
    }

    public post(url: string, data?: any, params?: THttpClientRequestOptions) {
        return this._makeRequestWithBody('post', url, data, params);
    }

    public put(url: string, data?: any, params?: THttpClientRequestOptions) {
        return this._makeRequestWithBody('put', url, data, params);
    }

    public patch(url: string, data?: any, params?: THttpClientRequestOptions) {
        return this._makeRequestWithBody('patch', url, data, params);
    }

    public request({ method, ...options }: THttpClientRequestParams & {
        method: THttpClientMethod,
    } & Omit<THttpClientRequestParams, 'method'>) {
        const { url, data, ...params } = options;
        return this[method](url, data, params);
    }
}