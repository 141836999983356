import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { BroadcastChannel } from 'broadcast-channel';
import { TUser } from '@/types/manager';
import { AuthState, AuthUser } from '@/state/auth.state';
import { useConfigController } from '@/controllers/config.controller';
import { API_BASE_URL_V2, API_URLS, UserRole } from '@/consts';
import { Jwt } from '@/libs/jwt';
import { useBeforeUnload } from '@/utils/hooks/use-before-unload';
import { useSocketsContext } from '@/libs/sockets/sockets.context';
import { useJwtContext } from '@/libs/jwt/jwt.context';
import { ApiClient } from '@/libs/api-client/api-client';
import { useApiClient } from '@/libs/api-client/use-api-client';


interface IAuthContext {
    user: TUser
    doLogoutUser: () => Promise<void>
    doAuthUser: (token: string) => Promise<void>
    isLoadingCheckAuth: boolean;
    checkRights: (userField: keyof TUser | (keyof TUser)[], equal: any) => boolean;
    checkRoles: (roles: UserRole[]) => boolean;
    fetchUser: () => Promise<void>;
}


const AuthContext = React.createContext<IAuthContext>({
    user: undefined,
    doLogoutUser: () => null,
    doAuthUser: () => null,
    isLoadingCheckAuth: true,
    checkRights: () => null,
    checkRoles: () => null,
    fetchUser: () => null,
});

export const useAuthContext = () => React.useContext(AuthContext);

export const AuthContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const bcRef = useRef<BroadcastChannel>();
    const { fetchConfig, resetConfig } = useConfigController();
    const [ isLoadingCheckAuth, setIsLoadingCheckAuth ] = useState(true);
    const setIsAuthState = useSetRecoilState(AuthState);
    const setAuthUser = useSetRecoilState(AuthUser);
    const { connect, disconnect } = useSocketsContext();
    const { saveTokenData, destroyJwtData, registerJwtFailCallback, getAccessToken } = useJwtContext();

    useEffect(() => {
        bcRef.current = new BroadcastChannel('auth');
        return () => {
            bcRef.current = null;
        };
    }, []);

    const { fetch } = useApiClient({
        url: API_URLS.AUTH.CURRENT_USER,
    });

    const logout = useCallback(() => {
        ApiClient.destroy();
        destroyJwtData();
        disconnect();
        resetConfig();
        setAuthUser(null);
        setIsAuthState(false);
    }, [ destroyJwtData, disconnect, resetConfig, setAuthUser, setIsAuthState ])

    const doLogoutUser = useCallback(async () => {
        await bcRef.current.postMessage({
            type: 'LOGOUT',
        });
        logout();
    }, [ logout ]);

    const fetchUser = useCallback(async () => {
        const user = await fetch();
        setAuthUser(user as TUser);
    }, [ fetch, setAuthUser ]);

    const auth = useCallback(async () => {
        await ApiClient.init(
            API_BASE_URL_V2,
            getAccessToken,
        );
        try {
            fetchUser();
        } catch (e) {
            await doLogoutUser();
            // eslint-disable-next-line no-console
            console.error(e);
            return;
        }
        setIsAuthState(true);
        connect();
        await fetchConfig();
    }, [ connect, doLogoutUser, fetchConfig, fetchUser, getAccessToken, setIsAuthState ])

    const doAuthUser = useCallback(async (token: string) => {
        Jwt.instance().setAuthData(token);
        saveTokenData(token);
        await auth();
        await bcRef.current.postMessage({
            type: 'LOGIN',
            data: token,
        });
    }, [ auth, saveTokenData ]);

    const handlerBCEvent = useCallback(async ({ type, data: token }) => {
        if (type === 'LOGIN') {
            Jwt.instance().setAuthData(token);
            saveTokenData(token);
            await auth();
        }
        if (type === 'LOGOUT') {
            await logout();
            return;
        }
    }, [ auth, logout, saveTokenData ]);

    useEffect(() => {
        bcRef.current.addEventListener("message", handlerBCEvent);
        return () => {
            bcRef.current?.removeEventListener("message", handlerBCEvent);
        };
    }, [ handlerBCEvent ]);

    useBeforeUnload(() => {
        disconnect();
    });

    useEffect(() => {
        registerJwtFailCallback(doLogoutUser);

        Jwt.instance().config({
            onRefreshTokenFail: async () => {
                await doLogoutUser();
            },
        })
            .init(async () => {
                await auth();
            })
            .catch(() => null)
            .finally(() => setIsLoadingCheckAuth(false));
    }, [ doLogoutUser, auth, registerJwtFailCallback ]);

    const user = useRecoilValue(AuthUser);

    function checkRights(userField: keyof TUser | (keyof TUser)[], equal: any): boolean {
        if (Array.isArray(userField)) {
            return userField.some((clauseField) => user[clauseField] === equal);
        }
        return user[userField] === equal;
    }

    function checkRoles(roles: UserRole[]): boolean {
        return user.roles.some((role) => roles.includes(role));
    }

    return (
        <AuthContext.Provider value={ {
            user,
            doLogoutUser,
            doAuthUser,
            isLoadingCheckAuth,
            checkRights,
            checkRoles,
            fetchUser,
        } }>
            { children }
        </AuthContext.Provider>
    );
};
