import React, { useCallback, useState } from 'react';
import { useModal } from 'finbox-ui-kit/utils/hooks';
import { transformUrlParams } from '@/utils';
import { API_URLS } from '@/consts';
import { ILeadTask } from '@/types/lead';
import { TaskForm } from '@/components/leads/components/lead-task-form';
import { useApiClient } from '@/libs/api-client/use-api-client';

interface IClientTasksContext {
    loading: boolean;
    addTask: (clientId: number, successCallback?: () => void, cancelCallback?: () => void) => void;
    fetchTasks: (clientId: number) => Promise<void>;
    tasks: ILeadTask[];
    removeTask: (clientId: number, taskId: number) => Promise<void>;
}

const ClientTasksContext = React.createContext<IClientTasksContext>({
    loading: false,
    tasks: [],
    addTask: () => null,
    fetchTasks: () => null,
    removeTask: () => null,
});

export const useClientTasksContext = () => React.useContext(ClientTasksContext);

function initialCallback() { return null; }

export const ClientTasksContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const [ tasks, setTasks ] = useState<ILeadTask[]>([]);
    const [ addTaskClientId, setAddTaskClientId ] = useState<number>(null);
    const [ addTaskCallback, setAddTaskCallback ] = useState(() => initialCallback);
    const [ addTaskCallbackCancel, setAddTaskCallbackCancel ] = useState(() => initialCallback);

    const { show: showTaskForm, props: taskFormProps } = useModal({
        onClose: () => {
            setAddTaskClientId(null);
            if (addTaskCallbackCancel) {
                addTaskCallbackCancel();
            }
        },
    });

    const { loading: isLoading, fetch } = useApiClient({
        url: API_URLS.LEADS.REMINDS,
    });

    const fetchTasks: IClientTasksContext['fetchTasks'] = useCallback(async (clientId: number) => {
        const response = await fetch(null, {
            url: transformUrlParams(API_URLS.LEADS.REMINDS, { id: clientId }),
        });
        setTasks(response.data);
    }, [ fetch ]);

    const addTask: IClientTasksContext['addTask'] = useCallback(async (clientId: number, successCallback: () => null, cancelCallback: () => null) => {
        setAddTaskClientId(clientId);
        setAddTaskCallback(() => successCallback);
        setAddTaskCallbackCancel(() => cancelCallback);
        showTaskForm();
    }, [ showTaskForm ]);

    const removeTask: IClientTasksContext['removeTask'] = useCallback(async (clientId: number, taskId: number) => {
        await fetch(null, {
            url: `${API_URLS.LEADS.TASKS}${taskId}/`,
            method: 'delete',
        });
        await fetchTasks(clientId);
    }, [ fetch, fetchTasks ]);

    const addTaskSubmitHandler = useCallback(async () => {
        await fetchTasks(addTaskClientId);
        setAddTaskClientId(null);
        addTaskCallback();
    }, [ addTaskCallback, addTaskClientId, fetchTasks ]);

    return (
        <ClientTasksContext.Provider value={{
            loading: isLoading,
            tasks,
            addTask,
            fetchTasks,
            removeTask,
        }}>
            { children }
            <TaskForm
                lid={ addTaskClientId }
                { ...taskFormProps }
                onSubmit={ addTaskSubmitHandler }
            />
        </ClientTasksContext.Provider>
    )
}
