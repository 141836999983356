import React, { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { UITopMenu } from 'finbox-ui-kit/components/top-menu';
import { TFUITopMenuItem } from 'finbox-ui-kit/components/top-menu/desktop/top-menu';
import { API_BASE_URL_V2, UserRole } from '@/consts';
import { request } from '@/utils';
import { TUser } from '@/types/manager';
import './style.scss';
import { TimeTracker } from '@/common/time-tracker';
import NotificationsIcon from '@/common/user-notifications';
import { useAuthContext } from '@/context/auth.context';
import { buildTopMenu } from '@/top-menu-stucture';
import { UserAvatar } from '@/common/user-avatar';
import { SearchGlobal } from './components/search';


interface ITopMenuProps {
    isAdmin: boolean;
    user: TUser;
}


const TopMenu = ({ user }: ITopMenuProps) => {
    const navigate = useNavigate();
    const { doLogoutUser, checkRoles } = useAuthContext();

    const logoutClickHandler = useCallback(async (e) => {
        e.preventDefault();
        const { error } = await request.post(`${ API_BASE_URL_V2 }/auth/logout`, null, true);
        if (error) {
            toast.error('Ошибка выхода');
            // eslint-disable-next-line no-console
            console.error(error.message);
        } else {
            toast.success('До скорых встреч! ❤');
        }
        await doLogoutUser();
        navigate('/');
    }, [ doLogoutUser, navigate ]);

    const menuItems = useMemo<TFUITopMenuItem[]>(() => [
        ...buildTopMenu(user),
        ...checkRoles([ UserRole.admin, UserRole.manager, UserRole.creditors_manager ]) ? [
            {
                render: (<SearchGlobal key='global_search'/>),
            },
        ] : [],
        ...checkRoles([ UserRole.admin, UserRole.manager ]) ? [
            {
                right: true,
                render: (
                    <TimeTracker/>
                ),
            },
        ] : [],
        {
            name: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={ { width: 44, padding: 8, verticalAlign: 'middle', marginRight: 2 } }>
                        <UserAvatar user={ user }/>
                    </div>
                    <div style={ { marginRight: 2 } }>{ user.name }</div>
                </div>
            ),
            right: true,
            items: [
                {
                    name: 'Профиль',
                    to: '/profile/',
                },
                {
                    name: 'Выход',
                    to: '/user/logout/',
                    onClick: logoutClickHandler,
                },
            ],
        },
    ], [ checkRoles, logoutClickHandler, user ]);

    return (
        <UITopMenu
            logoText='CRM'
            logoBackgroundColor='#bf0000'
            logoTextColor='#fff'
            logoLink='/'
            items={ menuItems }
            icons={ checkRoles([ UserRole.admin, UserRole.manager, UserRole.creditors_manager ]) && [
                {
                    render: (
                        <NotificationsIcon/>
                    ),
                },
            ] }
        />
    );
};

export default TopMenu;
