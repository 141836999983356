import React from 'react';
import wNumb from 'wnumb';
import round from 'lodash/round';
import isEmpty from 'lodash/isEmpty';
import { UIIcon, UILabel, UITooltip } from 'finbox-ui-kit';
import { isDefined } from 'finbox-ui-kit/utils';
import { MCreditorOrganizationTypeShortcut } from '@/consts';
import { TUser } from '@/types/manager';
import { TCreditor } from '@/types/creaditors';

export const formatNumber = (rawValue) => {
    if (rawValue === null || rawValue === undefined) {
        return null;
    }

    let value = String(rawValue);

    if (value.includes(',')) {
        const numbers = value.split(',').map(formatNumber);
        return (numbers) ? numbers.join(', ') : '';
    }

    const tmp = value.match(/[0-9]/g);

    value = (tmp) ? tmp.join('') : '';

    if (value.length === 10) {
        value = '7' + value;
    } else if (value.length === 11 && value[0] === '8') {
        value = '7' + value.substr(1, 12);
    }

    return value.toString().replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+7 ($2) $3-$4-$5');
};

export const phonePasteParser = (phone) => {
    if (!phone) {
        return null;
    }
    const digits = normalizePhone(phone);
    if ([ '7', '8' ].includes(digits[0])) {
        return digits.substr(1);
    }
    return digits;
};

export const normalizePhone = (v: string | number) => {
    if (!v) {
        return null;
    }
    const match = String(v).match(/[0-9]/g);

    if (match && match.length > 0 && match[0] === '8') {
        match[0] = '7';
    }
    return (match) ? match.join('') : '';
};


function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export const formatPrice = (value: string | number, withCurrency = true) => {
    if (!isDefined(value) || value === '') {
        return null;
    }
    const _val = (typeof value === 'string') ? String(value).match(/[0-9.]/g).join('') : value;

    if (!withCurrency) {
        return numberWithCommas(_val);
    }
    return `${ numberWithCommas(_val) } руб.`;
};

export const formatPriceFloat = (value) => {

    if (!value) {
        return null;
    }

    const moneyFormat = wNumb({
        mark: '.',
        thousand: ' ',
        prefix: '',
        decimals: 2,
        suffix: 'p.',
    });

    return moneyFormat.to(parseFloat(value));
};

export const decl = (number, titles) => {
    const cases = [ 2, 0, 1, 1, 1, 2 ];
    return titles[(number % 100 > 4 && number % 100 < 20)
        ? 2
        : cases[(number % 10 < 5) ? number % 10 : 5]];
};

export const declYears = (val) =>
    `${ round(val, 2) } ${ decl(Math.ceil(val), [ 'год', 'года', 'лет' ]) }`;

export const declMonth = (val) =>
    `${ round(val, 2) } ${ decl(round(val, 2), [ 'мес', 'мес', 'мес' ]) }`;

export const declDay = (val) =>
    `${ val } ${ decl(val, [ 'день', 'дня', 'дней' ]) }`;

export const getPeriod = (val) => {
    if (!val || val < 1) {
        return '-';
    } else if (val && val >= 12) {
        return declMonth(val);
    }

    return declYears(val);
};

export const formatBankName = (creditor: TCreditor, managers?, asString = false, clickHandler?: () => void): string | React.ReactElement => {
    const creditorName = [];

    if (!creditor) {
        return '-';
    }

    creditorName.push(creditor.name);

    if (creditor.organizationType) {
        creditorName.push(MCreditorOrganizationTypeShortcut.get(creditor.organizationType));
    }

    let additional = '';
    let manager: TUser;
    if (creditor.assignee || creditor.user) {
        manager = creditor.assignee || creditor.user;
    } else if (creditor.assigneeId && managers && managers.length > 0) {
        manager = managers.find((manager) => manager.id === creditor.assigneeId);
    }
    if (manager && manager.name && manager.surname) {
        const initials = [
            manager.surname[0],
            manager.name[0],
            manager.patronymic ? manager.patronymic[0] : '',
        ];
        additional += `[${ initials.join('').toUpperCase() }]`;
    }

    if (additional) {
        creditorName.push(additional);
    }

    if (asString) {
        return creditorName.join(' ');
    }

    return (
        <span>
            { clickHandler && (
                <span className='color-blue cursor-pointer' onClick={ clickHandler }>
                    { creditorName.join(' ') }
                </span>
            ) }
            { !clickHandler && creditorName.join(' ') }

            { creditor.finboxConnected && (
                <UITooltip
                    trigger={ (ref) => (
                        <UILabel ref={ ref } color='green' className='ml-5'>
                            <UIIcon name='f'/>
                        </UILabel>
                    ) }
                    content='Кредитор поключен к системе Finbox'
                    position='bottom center'
                />
            ) }
        </span>
    );
};

export const formatShortSum = (value) => {

    const formatFloat = (val) => Math.floor(val * 100) / 100;

    if (!value) {
        return 0;
    }

    const sum = parseInt(value, 10);

    if (sum >= 1000 && sum < 1000000) {
        return `${ formatFloat(sum / 1000) } т.`;
    } else if (sum >= 1000000) {
        return `${ formatFloat(sum / 1000000) } млн.`;
    }

    return sum;

};

export const formatAddress = (address) => {
    if (!address || isEmpty(address)) {
        return null;
    }
    const tmp = [];

    if (address.region) {
        tmp.push(address.region_with_type);
    }

    if (address.area) {
        tmp.push(address.area);
    }

    if (address.city) {
        tmp.push(`г. ${ address.city }`);
    }

    if (address.settlement) {
        tmp.push(address.settlement);
    }

    if (address.street) {
        tmp.push(`${ address.street_type || 'ул' }. ${ address.street }`);
    }

    if (address.house) {
        tmp.push(`д. ${ address.house }`);
    }

    if (address.block) {
        tmp.push(`к. ${ address.block }`);
    }

    if (address.flat) {
        tmp.push(`кв. ${ address.flat }`);
    }
    return tmp.filter((i) => i).join(', ');
};

export const capitalize = (v: string): string => !v
    ? v
    : v.split(' ').map((i) => i.trimLeft().charAt(0).toUpperCase() + i.trimLeft().toLowerCase().slice(1)).join(' ');

export const formatManagerInitials = (manager: TUser) =>
    manager ? [ manager.surname, manager.name ].join(' ') : null;
