import React, { useRef, useState } from 'react';
import { UIButton, UIFieldset, UIInput, UIOptionsList, UIPopup, UITag } from 'finbox-ui-kit';
import { useDebounce, useToggle } from 'finbox-ui-kit/utils/hooks';
import { TUIOptionsSelectHandler } from 'finbox-ui-kit/components/options-list/options';
import { Validator } from 'finbox-ui-kit/libs/validator';
import { API_URLS, CreditorContactTargetMap, ECreditorContactTarget } from '@/consts';
import { EMAIL_REGEX_I } from '@/utils/regex';
import { useApiClient } from '@/libs/api-client/use-api-client';

type TSuggestion = {
    name: string;
    description: string;
    email: string;
    type: ECreditorContactTarget;
}

export const TargetPicker = ({ name: fieldName, label, error, value, onChange }) => {
    const inputSearchRef = useRef<HTMLInputElement>();
    const [ addShowed, setAddShowed ] = useState(false);
    const [ addressValue, setAddressValue ] = useState('');
    const { debounce } = useDebounce({ timeout: 1000 });
    const { on, toggle } = useToggle();

    const { data: suggestions, fetch, loading: isLoading, reset } = useApiClient<{ [x: string]: TSuggestion[] }>({
        url: API_URLS.EMAIL.SUGGESTIONS,
    });

    const addButtonClickHandler = () => {
        setAddShowed(true);
        setAddressValue('');
        reset();
        setTimeout(() => {
            inputSearchRef.current.focus();
        }, 20);
    };
    const outsideClickHandler = () => {
        if (!isLoading) {
            setAddShowed(false);
        }
    };

    const removeItemClickHandler = (index: number) => () => {
        onChange(null, { name: fieldName, value: value.filter((_, k: number) => k !== index) });
    };

    const inputKeyPressHandler = (e) => {
        if (e.key === 'Enter') {
            if (Validator.email(addressValue.trim(), true) === true) {
                onChange(null, {
                    name: fieldName,
                    value: [
                        ...value,
                        {
                            name: addressValue,
                            address: addressValue,
                        },
                    ],
                });
                outsideClickHandler();
            }
        }
    };

    const handlerBlurInput = (e, { value: inputValue }) => {
        if (!inputValue) {
            setAddShowed(false);
        }
        if (EMAIL_REGEX_I.test(inputValue)) {
            const suggestion = suggestions?.creditors?.find((i) => i.email === inputValue);
            setAddressValue('');

            onChange(null, {
                name: fieldName,
                value: [
                    ...value,
                    {
                        name: suggestion?.name || inputValue,
                        address: inputValue,
                    },
                ],
            });
        }
    };

    const handlerChangeSearch = (e, { value }) => {
        setAddressValue(value);
        debounce(async () => {
            await fetch({
                s: value,
            });
            toggle(true);
        });
    };

    const handlerSelect: TUIOptionsSelectHandler = ({ data }) => {
        setAddressValue('');
        onChange(null, {
            name: fieldName,
            value: [
                ...value,
                {
                    name: data.name || data.email,
                    address: data.email,
                },
            ],
        });
        toggle(false);
    };

    const handlerClickOutside = () => {
        setAddShowed(false);
        toggle(false);
    };

    return (
        <UIFieldset legend={ label } className='mb0'>
            <div>
                <UITag.Group className='inlineFlex'>
                    { value && value.map((item, index) => (
                        <UITag
                            key={ index }
                            value={ item.name }
                            content={ `${ item.name } <${ item.address }>` }
                            onRemove={ removeItemClickHandler(index) }
                        />
                    )) }
                    { !addShowed && (
                        <UIButton
                            icon='plus'
                            onClick={ addButtonClickHandler }
                            content='Добавить получателя'
                            size='small'
                            color='white'
                        />
                    ) }
                    { addShowed && (
                        <UIPopup.Wrapper style={ { display: 'block', zIndex: 9, flex: '1 1 100%' } }>
                            <UIInput
                                className='mt-5'
                                ref={ inputSearchRef }
                                name='target-search'
                                onChange={ handlerChangeSearch }
                                value={ addressValue }
                                onKeyDown={ inputKeyPressHandler }
                                onBlur={ handlerBlurInput }
                                label='Введите адрес или название кредитора'
                                noMargin
                            />
                            <UIPopup
                                targetRef={ inputSearchRef }
                                open={ on }
                                onClickOutside={ handlerClickOutside }
                                minWidth='100%'
                            >
                                { suggestions && (
                                    <UIOptionsList
                                        options={
                                            Object.values(suggestions).reduce((acc, contacts) => [ ...acc, ...contacts ], [])
                                                .map((contact) => ({
                                                    text: (
                                                        <div>
                                                            { contact.description && (
                                                                <div className='fz12 mb_5'>
                                                                    { contact.description }&nbsp;
                                                                    [{ CreditorContactTargetMap.get(contact.type) }]
                                                                </div>
                                                            ) }
                                                            { contact.name || contact.email } &lt;{ contact.email }&gt;
                                                        </div>
                                                    ),
                                                    value: contact.email,
                                                    data: contact,
                                                }))
                                        }
                                        onSelect={ handlerSelect }
                                        filtering={ false }
                                        fluid
                                    />
                                ) }
                            </UIPopup>
                        </UIPopup.Wrapper>
                    ) }
                </UITag.Group>
            </div>
            { error && (
                <p className='-error color-red fz12'>{ error }</p>
            ) }
        </UIFieldset>
    );
};
