import React from 'react';
import cn from 'classnames';
import { TUIFieldsetProps } from 'finbox-ui-kit/components/fieldset/fieldset';
import styles from './fieldset.module.scss';

export const UIFieldset: React.FC<TUIFieldsetProps> = ({ legend, children, className, error }) => (
    <fieldset className={cn(
        styles.FUIFieldset,
        className,
        {
            [styles.Errored]: error,
        },
    )}>
        <legend>{ legend }</legend>
        {error && (
            <p className={styles.FUIFieldsetError}>{ error }</p>
        )}
        { children }
    </fieldset>
);

