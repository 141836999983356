import { jwtDecode } from 'jwt-decode';
import { DateTime } from 'luxon';
import { TAccessData } from './jwt.context';

export function decodeAuthData(accessToken: string): TAccessData {
    const JWTDataAccess = jwtDecode<any>(accessToken);
    return ({
        accessToken,
        accessTokenExpire: DateTime.now().plus({ second: JWTDataAccess.accessTTL }).toMillis(),
        refreshTokenExpire: DateTime.now().plus({ second: JWTDataAccess.refreshTTL }).toMillis(),
    });
}