import React from 'react';
import { UIButton, UIModal } from 'finbox-ui-kit';
import { ProgramSelectionErrors } from '@/components/leads/components/order-selection/components/program-selection-errors';

type TErrorsPopupProps = {
    open: boolean,
    onClose: () => void,
    onClickEdit: (tab: string) => void,
    errors: any[],
    joiErrors?: boolean,
}
export const ErrorsPopup: React.FC<TErrorsPopupProps> = ({ open, onClose, errors, onClickEdit, joiErrors }) => (
    <UIModal open={ open } onClose={ onClose } size='small'>
        <UIModal.Header>Ошибки заполнения анкеты</UIModal.Header>
        <UIModal.Content>
            <ProgramSelectionErrors
                text='Для формирования документа необходимо исправить ошибки'
                errors={ errors }
                onClickEdit={ onClickEdit }
                joiErrors={joiErrors}
            />
        </UIModal.Content>
        <UIModal.Actions className='text-left'>
            <UIButton
                content='Закрыть'
                onClick={ onClose }
            />
        </UIModal.Actions>
    </UIModal>
);
