import { TUser } from '@/types/manager';
import { formatManagerInitials } from '@/utils/formatter';
import { ParamParseKey, Params } from '@/types/parse-path';

export { default as request } from './request';
export { default as Storage } from '@/utils/storage';
export * from './fsa';
export * from './formatter';
export * from './uid';
export * from './error-handler';
export * from './createReducer';
export * from './hooks';
export * from './prettify-phone';
export * from './decl-num';
export * from './scheme';
export * from './is-object-empty-deep';


export const getProductName = (products, id) => {
    if (products) {
        const product = products.find((i) => parseInt(i.id, 10) === parseInt(id, 10));
        if (product && product.name) {
            return product.name;
        }
    }
    return null;
};

export const getManagerName = (managers, id) => {
    const mngr = managers.find((i) => parseInt(i.id, 10) === parseInt(id, 10));
    return (mngr && mngr.name) ? formatManagerInitials(mngr) : '';
};

export const getManagerInitials = (managers: TUser[], id) => {
    const mngr = managers.find((i) => i.id === parseInt(id, 10));
    if (!mngr) {
        return '';
    }
    return `${ mngr.surname } ${ mngr.name }`;
};

export const parseQuery = (queryString) => {
    const query = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=');
        if (pair[0] && pair[1]) {
            query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        }
    }
    return query;
};

export const toQueryParams = (arr: Record<string, string | number | boolean>): string => Object.entries(arr).reduce((res, [ key, value ]) => [
    ...res,
    `${ key }=${ value }`,
], []).join('&');

export const transformUrlParams = (url, params) => {
    const re = /(<(.*?)>)/ig;
    let match;

    let result = url;

    // eslint-disable-next-line
    while ((match = re.exec(url)) !== null) {
        if (match.index === re.lastIndex) {
            re.lastIndex++;
        }

        if (Object.keys(params).includes(match[2])) {
            result = result.replace(match[0], params[match[2]]);
        }
    }
    return result;
};

export const replaceUrlPatterns = <ParamKey extends ParamParseKey<Path>, Path extends string>(url: Path, replacements: Params<ParamKey>): string => {
    const mapObj = Object.entries(replacements).reduce((acc, [ key, value ]) => ({
        ...acc,
        [`:${ key }`]: value,
    }), {});

    const re = new RegExp(Object.keys(mapObj).join("|"), "gi");
    return url.replace(re, (matched) => mapObj[matched]);
};

export const trimSlashes = (str) => {
    str = str.trim();
    const start = str[0] === '/' ? 1 : 0;
    const end = str[str.length - 1] === '/' ? str.length - 1 : str.length;
    return str.slice(start, end);
};
