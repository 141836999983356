import React, { ChangeEvent, ReactNode, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import cn from 'classnames';
import { UIPopup } from 'finbox-ui-kit';
import { FORMAT } from 'finbox-ui-kit/consts';
import { useModal } from 'finbox-ui-kit/utils/hooks';
import { useEventListener } from 'finbox-ui-kit/utils/hooks/use-event-listener';
import { useUpdate } from 'finbox-ui-kit/utils/hooks/use-update';
import { TSize } from '@/types/common';
import { Nullable } from '@/types/_helpers';
import { Input } from '@/common/ui/input';
import { DatePickerCalendar } from '@/common/ui/date-picker-calendar';
import { ErrorText } from '@/common/ui/error-text';
import { TInputChangeData } from '@/common/ui/input/types';
import { DatePickerControls } from '@/common/ui/date-picker-controls';
import styles from './date-picker.module.scss';


const getInputValue = (val: Nullable<DateTime>, showTimePicker: boolean) => val ? val.toFormat(showTimePicker ? FORMAT.DATETIME : FORMAT.DATE) : '';
const getTimeValue = (val: DateTime) => val ? val.toFormat(FORMAT.TIME) : '';


type DatePickerProps = {
    id?: string;
    name: string;
    label: ReactNode;
    value: Nullable<DateTime | string>;
    onChange: (date: Nullable<DateTime>, options: {
        name: string,
        value: Nullable<DateTime>;
    }) => void;
    className?: string
    startDate?: DateTime;
    disabled?: boolean;
    readOnly?: boolean;
    error?: string;
    placeholder?: string;
    minDate?: DateTime;
    size?: TSize;
    loading?: boolean;
    required?: boolean;
    showTimePicker?: boolean;
};

export const DatePicker = React.forwardRef<HTMLInputElement, DatePickerProps>(function DatePicker({
    className,
    value,
    startDate,
    onChange,
    id,
    name,
    label,
    disabled,
    readOnly,
    error,
    placeholder,
    minDate,
    size,
    loading,
    required,
    showTimePicker,
}, forwardRef) {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);


    const _value = value ?
        value instanceof DateTime
            ? value
            : DateTime.fromISO(value)
        : null;

    const { show, hide, props } = useModal({ hideScroll: false });
    const [ currentMonth, setCurrentMonth ] = useState(startDate || _value || DateTime.now());
    const [ inputValue, setInputValue ] = useState<string | null>(getInputValue(_value, showTimePicker));
    const [ timeValue, setTimeValue ] = useState<string>(getTimeValue(_value));


    const handlerKeydown = useCallback((e) => {
        if (e.key === 'Tab') {
            hide();
        }
    }, [ hide ]);

    useImperativeHandle<HTMLInputElement | null, any>(forwardRef, () => ({
        target: {
            value,
        },
        focus() {
            inputRef.current?.focus();
            show();
        },
    }), [ show, value ]);

    useEventListener('keydown', handlerKeydown);

    useEffect(() => {
        if (typeof value === 'string') {
            onChange(null, { name, value: DateTime.fromISO(value) });
        }
    }, [ name, onChange, value ]);

    useUpdate(() => {
        setInputValue(getInputValue(_value, showTimePicker));
    }, [ value ]);

    function handlerPickDate(day: DateTime) {
        onChange(day, { name, value: day });
        hide();
    }

    function handlerChangeByControls(date: DateTime) {
        onChange(date, { name, value: date });
        setCurrentMonth(date);
        setInputValue(getInputValue(date, showTimePicker));
    }

    const handlerClickInput = () => {
        if (disabled || readOnly) {
            return;
        }
        show();
        if (!props.open) {
            setCurrentMonth(startDate || _value || DateTime.now());
        }
    };

    function handlerChangeInput(_: ChangeEvent<HTMLInputElement>, { value }: TInputChangeData<string>) {
        if (!value) {
            hide();
            onChange(null, { name, value: null });
            return;
        }
        const date = DateTime.fromFormat(value, FORMAT.DATE);
        if (date.isValid) {
            hide();
            onChange(date, { name, value: date });
        }
    }

    function handlerChangeTime(_: ChangeEvent<HTMLInputElement>, { value: inputValue }: TInputChangeData<string>) {
        const time = DateTime.fromFormat(inputValue, FORMAT.TIME);
        setTimeValue(inputValue);
        if (time.isValid) {
            const datetime = _value.set({
                hour: time.hour,
                minute: time.minute,
            });
            onChange(datetime, { name, value: datetime });
        }
    }

    return (
        <div className={ cn(styles.datePicker, className) }>
            <div ref={ wrapperRef } className={ styles.datePickerWrapper }>
                <Input
                    ref={ inputRef }
                    id={ id }
                    name={ name }
                    label={ label }
                    value={ inputValue }
                    inputMode='text'
                    mask={ showTimePicker ? '99.99.9999 99:99' : '99.99.9999' }
                    onChange={ handlerChangeInput }
                    onClick={ handlerClickInput }
                    onFocus={ () => show() }
                    readOnly={ readOnly }
                    autoComplete='off'
                    clearable={ !readOnly }
                    error={ !!error }
                    disabled={ disabled }
                    placeholder={ placeholder }
                    size={ size }
                    loading={ loading }
                    required={ required }
                />
                <UIPopup
                    targetRef={ wrapperRef }
                    open={ props.open }
                    onClickOutside={ () => hide() }
                    onClose={ () => {
                        hide();
                        inputRef.current?.blur();
                    } }
                >
                    <div className={ styles.datePickerPopup }>
                        <DatePickerControls
                            current={ currentMonth }
                            onChange={ handlerChangeByControls }
                        />
                        <DatePickerCalendar
                            onClickDay={ handlerPickDate }
                            currentMonth={ currentMonth }
                            onRenderDay={ (day) => ({
                                selected: !!_value?.hasSame(day, 'day'),
                                disabled: !!(minDate && day < minDate),
                                hasBefore: false,
                                hasAfter: false,
                            }) }
                        />
                        { showTimePicker && (
                            <Input
                                className='mt_5'
                                name='time'
                                label='Время'
                                mask='99:99'
                                value={ timeValue }
                                onChange={ handlerChangeTime }
                                clearable
                            />
                        ) }
                    </div>
                </UIPopup>
            </div>

            { (error && typeof error === 'string') && (
                <ErrorText text={ error }/>
            ) }
        </div>
    );
});