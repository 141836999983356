/* eslint-disable no-underscore-dangle */
import { omit } from 'lodash';
import { jwtDecode } from 'jwt-decode';
import { DateTime } from 'luxon';
import { Storage } from '@/utils/storage';
import { TAuthData } from '@/types/auth-data';
import { request } from '@/utils/request';
import { API_BASE_URL_V2 } from '@/consts';


class Jwt {
    private static _instance: Jwt;
    /**
     * Бефер запроа токена
     * @type {null|Promise}
     * @private
     */
    private BUFFER: Promise<any> = null;

    /**
     * Время в секундах, которое определяет за сколько до истечения времени жизни токена запрашивать
     * новый
     * @type {number}
     */
    private EXPIRES_IN = 60;

    static instance() {
        if (!this._instance) {
            this._instance = new Jwt();
        }
        return this._instance;
    }

    /**
     * Метод вызываемый при ошибке запроса токена
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private _refreshTokenFail = (error: any): void => null;

    /**
     * Get refresh token instance
     */
    private _getRefreshInstance(silent?: boolean) {
        if (!this.BUFFER) {
            this.BUFFER = this.refreshAccessToken(false, silent);
        }
        return this.BUFFER;
    }

    /**
     * Обработчик ошибки
     * @param error
     * @private
     */
    private _errorHandler(error: string) {
        // eslint-disable-next-line no-console
        console.error(error)
        this._refreshTokenFail(error);
    }

    /**
     * Обновление токена
     */
    async refreshAccessToken(returnRefreshToken = false, silent?: boolean) {
        const { response: refreshData } = await request.get(
            `${ API_BASE_URL_V2 }/auth/refresh-token/`,
            null, false, {
                useToken: false,
                requestConfig: {
                    withCredentials: true,
                },
            });

        if (!refreshData || !refreshData.token) {
            if (!silent) {
                this._errorHandler('Refresh token failed');
            }
            return;
        }

        this.setAuthData(refreshData.token);

        if (!returnRefreshToken) {
            return refreshData.token;
        }
        return refreshData;
    }

    /**
     * Проверка актуальности токена
     * @param tokenExpire
     * @returns {boolean}
     */
    isTokenExpired(tokenExpire: number) {
        return DateTime.fromMillis(tokenExpire) < DateTime.now().plus({ second: this.EXPIRES_IN });
    }

    /**
     * Декодирование JWT данных и приведение их к формату
     */
    decodeAuthData = (accessToken: string): TAuthData => {
        const JWTDataAccess = jwtDecode<any>(accessToken);
        return ({
            accessToken,
            accessTokenExpire: DateTime.now().plus({ second: JWTDataAccess.accessTTL }).toMillis(),
            refreshTokenExpire: DateTime.now().plus({ second: JWTDataAccess.refreshTTL }).toMillis(),
            user: JWTDataAccess.user,
        });
    };

    /**
     * Запись авторизационных данных в хранилище
     * @param accessToken
     */
    setAuthData(accessToken: string): void {
        Storage.setItem('auth', omit(this.decodeAuthData(accessToken), [ 'user' ]));
    }

    /**
     * Удаление авторизационных данных из хранилище
     */
    destroyAuthData(): void {
        Storage.removeItem('auth');
    }

    /**
     * Get access token for Authorization header
     * @returns {Promise}
     */
    async getAccessToken(silent?: boolean): Promise<string> {
        const accessData = Storage.getItem('auth');

        if (!accessData) {
            if (!silent) {
                this._errorHandler('Unauthorized');
            }
            return null;
        }
        const { accessToken, accessTokenExpire, refreshTokenExpire } = accessData;
        if (this.isTokenExpired(accessTokenExpire)) {
            if (this.isTokenExpired(refreshTokenExpire)) {
                if (!silent) {
                    this._errorHandler('Refresh token expired');
                }
                return null;
            }
            try {
                const data = await this._getRefreshInstance(silent);
                this.BUFFER = null;
                return data;
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);
                if (!silent) {
                    this._errorHandler(e);
                }
                return null;
            }
        }
        return accessToken;
    }

    config({
        onRefreshTokenFail,
    }: any) {
        this._refreshTokenFail = onRefreshTokenFail;
        return this;
    }

    init(cb: any) {
        const accessData = Storage.getItem('auth');
        if (accessData) {
            return this.getAccessToken()
                .then(cb);
        }
        return Promise.reject('Access is not granted');
    }
}

export {
    Jwt,
};
